import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { GlobalService } from '../admin/GlobalService/global-service';
import { helper, TypedJson } from './helper';
import { Router, ActivatedRoute } from '@angular/router';
import { user } from '../admin/cx-users/cx-users.model';
import { promise } from 'protractor';

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    helper = new helper();
    _location: any;
    maskType: number;
    readonly CNUS = 0; //14 digits//US
    readonly CNNONUS = 1; //15 digits//NON US
    constructor(private location: Location, private global: GlobalService, private router: Router, private activated_route: ActivatedRoute) {
        this._location = location;
    }
    getBaseUrl(): String {
        var currentAbsoluteUrl = window.location.href;
        var currentRelativeUrl = this._location.path();
        if (this.isEmpty(currentRelativeUrl)) {
            if (currentAbsoluteUrl.endsWith("/")) {
                currentAbsoluteUrl = currentAbsoluteUrl.replace(/\/$/, '');
            }
            return currentAbsoluteUrl;
        }
        else {
            var index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
            return currentAbsoluteUrl.substring(0, index);
        }
    }

    isEmpty = function (str: any) {
        return (typeof str === "undefined" || !str || 0 === str.length);
    }
    isObjectEmpty(obj: Object) {
        return Object.keys(obj).length === 0;
    }
    ValidateEmail(mail) {
        var res = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
        var re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/igm;
        var res1 = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/igm;
        res1 = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm
        if (!res1.test(mail)) {
            return false;
        }
        else { return true; }
    }

    VeriryStringIsEmpty(data) {
        return (data !== null && (data.length === 0 || !data.trim()));
    };
    received_rma(rMATRackingSerial, user_id) {
        this.helper.spName = "usp_received_rma_via_serial_and_rma_number";
        let param: any = {};
        param.searchItem = rMATRackingSerial;
        param.userid = user_id;
        this.helper.payload = JSON.stringify(param);
        this.global.ServiceManager.request.post('Grid/GetDataFromServer', this.helper).subscribe(
            (res: any) => {
                if (res.status == 1) {
                    let received_data: any = {};
                    received_data = JSON.parse(res.data.dataset.table[0].error_detail) === null ? {} :
                        JSON.parse(res.data.dataset.table[0].error_detail)[0];
                    if (received_data.status === 'error') {
                        this.global.utilities.notify.error(received_data.message);
                    }
                    else if (received_data.status === 'already_received') {
                        //let receivedRoute = 'en-us/support/received_item/' + received_data.message;
                        //this.router.navigate([receivedRoute]);
                        this.global.utilities.notify.warning("RMA# / Serial# / Tracking# has already been received.")
                    }
                    else {
                        let receivedRoute = 'en-us/support/received_item/' + received_data.message;
                        this.router.navigate([receivedRoute]);
                        this.global.utilities.notify.success("RMA has been received successfully.");
                    }
                }
                else {
                    this.global.utilities.notify.error("Error occur while receiving RMA. Please contact with admin.")
                }
            },
            error => {
                this.global.utilities.notify.error("Error occur while receiving RMA. Please contact with admin.")
            }
        );

    }
    /////Assign Ticket awaiting for shipment
    assignTicket_afs(rMATRackingSerial, user_id) {
        // debugger;
        let param: any = {};
        param.internal_code = rMATRackingSerial;
        param.user_id = user_id;
        this.helper.payload = JSON.stringify(param);
        this.helper.spName = "usp_post_assign_to_ship_aw_sh";
        let NavigationEnd;

        this.global.ServiceManager.request.post('Grid/GetDataFromServer', this.helper).subscribe(
            (res: any) => {
                let sucess;
                let url;
                if (res.status == 1) {
                    sucess = res.data.dataset.table[0];
                    if (res.data.dataset.table1 != undefined)
                        url = res.data.dataset.table1[0];
                    if (sucess.column1 == 'success') {
                        this.global.utilities.notify.success('Shipment assigned successfully', 'Success');
                        this.router.navigate(['en-us/support/workbench_shipment/' + url.column1]);
                        // this.redirectTo('en-us/support/workbench_shipment/' + url.column1);
                    }
                    if (sucess.column1 == 'error')
                        this.global.utilities.notify.warning('Invalid LPN#', 'Warning');
                    // this._assignmentPopup.hide();
                }
                else {
                    this.global.utilities.notify.error('Error to assign shipment', 'Error');
                }
            })
    }
    /////Assign Ticket awaiting for shipment
    assignTicket_acp(rMATRackingSerial, user_id) {
        // debugger;
        let param: any = {};
        param.internal_code = rMATRackingSerial;
        param.user_id = user_id;
        this.helper.payload = JSON.stringify(param);
        this.helper.spName = "usp_post_assign_to_ship_acp";
        let NavigationEnd;

        this.global.ServiceManager.request.post('Grid/GetDataFromServer', this.helper).subscribe(
            (res: any) => {
                let sucess;
                let url;
                //  debugger;
                if (res.status == 1) {
                    sucess = res.data.dataset.table[0];
                    if (res.data.dataset.table1 != undefined)
                        url = res.data.dataset.table1[0];
                    if (sucess.column1 == 'success') {
                        this.global.utilities.notify.success('Shipment assigned successfully', 'Success');
                        // this.router.navigate(['en-us/support/workbench_shipment/' + url.column1]);
                        // this.redirectTo('en-us/support/workbench_shipment/' + url.column1);
                        this.router.navigate(['en-us/support/workbench_shipment/' + url.column1]);
                    }
                    if (sucess.column1 == 'error')
                        this.global.utilities.notify.warning('Invalid LPN#', 'Warning');
                    // this._assignmentPopup.hide();
                }
                else {
                    this.global.utilities.notify.error('Error to assign shipment', 'Error');
                }
            })
    }

    assign_and_do_packaging(rMATRackingSerial, user_id) {
        if (rMATRackingSerial === undefined) {
            this.global.utilities.notify.warning('Please enter serial# / Repair Order#', 'Warning');
            return false;
        }
        let param: any = {};
        param.internal_code = rMATRackingSerial;
        param.user_id = user_id;
        this.helper.payload = JSON.stringify(param);
        this.helper.spName = "usp_assign_and_do_packaging";
        this.global.ServiceManager.request.post('Grid/GetDataFromServer', this.helper).subscribe(
            (res: any) => {
                if (res.status == 1) {
                    let received_data: any = {};
                    received_data = JSON.parse(res.data.dataset.table[0].error_data) === null ? {} :
                        JSON.parse(res.data.dataset.table[0].error_data)[0];
                    if (received_data.status === 1) {
                        this.global.utilities.notify.success('Serial# / Repair order# has been scanned successfully', 'Success');
                        this.router.navigate(['en-us/support/packaging_workbench/' + received_data.message]);
                    }
                    else {
                        this.global.utilities.notify.error(received_data.message, 'Error');
                    }
                }
                else {
                    this.global.utilities.notify.error('Error occur while scanning serial# / Repair order#', 'Error');
                }
            })
    }
    /////Assign Ticket awaiting for shipment
    //assignTicket_afs(rMATRackingSerial, user_id) {
    //    // debugger;
    //    let param: any = {};
    //    param.internal_code = rMATRackingSerial;
    //    param.user_id = user_id;
    //    this.helper.payload = JSON.stringify(param);
    //    this.helper.spName = "usp_post_assign_to_ship_afs";
    //    let NavigationEnd;

    //    this.global.ServiceManager.request.post('Grid/GetDataFromServer', this.helper).subscribe(
    //        (res: any) => {
    //            let sucess;
    //            let url;
    //            if (res.status == 1) {
    //                sucess = res.data.dataset.table[0];
    //                url = res.data.dataset.table1[0];
    //                if (sucess.column1 == 'success') {
    //                    this.global.utilities.notify.success('Shipment assigned successfully', 'Success');
    //                    this.router.navigate(['en-us/support/workbench_shipment/' + url.column1]);
    //                    // this.redirectTo('en-us/support/workbench_shipment/' + url.column1);
    //                }
    //                if (sucess.column1 == 'error')
    //                    this.global.utilities.notify.warning('Invalid LPN#', 'Warning');
    //                // this._assignmentPopup.hide();
    //            }
    //            else {
    //                this.global.utilities.notify.error('Error to assign shipment', 'Error');
    //            }
    //        })
    //}

    assignTicket(rMATRackingSerial, user_id) {
        //debugger;
        let param: any = {};
        param.internal_code = rMATRackingSerial;
        param.user_id = user_id;
        this.helper.payload = JSON.stringify(param);
        this.helper.spName = "usp_post_assign_to_ship_ws";
        let NavigationEnd;

        this.global.ServiceManager.request.post('Grid/GetDataFromServer', this.helper).subscribe(
            (res: any) => {
                let sucess;
                let url;
                // debugger;
                if (res.status == 1) {
                    sucess = res.data.dataset.table[0];
                    if (res.data.dataset.table1 != undefined)
                        url = res.data.dataset.table1[0];
                    if (sucess.column1 == 'success') {
                        //this.global.utilities.notify.success('Shipment assigned successfully', 'Success');
                        //this.router.navigate(['en-us/support/workbench_shipment/']);
                        this.router.navigate(['en-us/support/workbench_shipment/' + url.column1]);
                    }
                    if (sucess.column1 == 'error')
                        this.global.utilities.notify.warning('Invalid LPN#', 'Warning');
                    // this._assignmentPopup.hide();
                }
                else {
                    this.global.utilities.notify.error('Error to assign shipment', 'Error');
                }
            })
    }
    ////
    redirectTo(uri: string) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate([uri]));
    }
    /////
    Goto_work_Bench(rMATRackingSerial) {
        this.helper.spName = "usp_get_internal_code_for_work_bench";
        let param: any = {};
        param.searchItem = rMATRackingSerial;
        this.helper.payload = JSON.stringify(param);
        this.global.ServiceManager.request.post('Grid/GetDataFromServer', this.helper).subscribe(
            (res: any) => {
                if (res.status == 1) {
                    if (JSON.parse(res.data.dataset.table[0].internal_code) === null) {
                        this.global.utilities.notify.error("Repair order is not exist in system.")
                    }
                    else {
                        let receivedRoute = 'en-us/support/workbench/' + JSON.parse(res.data.dataset.table[0].internal_code)[0].internal_code;
                        this.router.navigate([receivedRoute]);
                    }
                }
                else {
                    this.global.utilities.notify.error("Repair order is not exist in system.")
                }
            },
            error => {
                this.global.utilities.notify.error("Repair order is not exist in system.")
            }
        );
    }
    send_receiving_emailto_customer(rMATRackingSerial) {
        let param: any = {};
        param.rmaNumber = rMATRackingSerial;
        param.skuNumber = "Test";
        param.companyName = "Test";
        this.global.ServiceManager.request.post('Email/SendReceivingEmailToCustomer', param).subscribe(
            (res: any) => {
                if (res.status == 1) {
                    console.log('Receiving Email sent to Customer');
                }
            },
            error => {
                console.log(error);
            }
        );
    }
    shippedRMA(rMATRackingSerial, user_id) {
        this.helper.spName = "usp_post_outbound_shipping_by_rma";
        let param: any = {};
        param.searchItem = rMATRackingSerial;
        param.userid = user_id;
        this.helper.payload = JSON.stringify(param);
        this.global.ServiceManager.request.post('Grid/GetDataFromServer', this.helper).subscribe(
            (res: any) => {
                if (res.status == 1) {
                    let message = JSON.parse(res.data.dataset.table[0].rma_detail)[0];
                    if (message != null && message.status == "success") {
                        let shipRoute = 'en-us/support/outbound_shipment';//+ JSON.parse(res.data.dataset.table[0].rma_detail)[0].internal_code;
                        ///' + JSON.parse(res.data.dataset.table[0].rma_detail)[0].internal_code

                        this.router.navigate([shipRoute]);
                        this.global.utilities.notify.success("RMA has been shipped successfully.", "Confirmation")
                    } else {
                        if (message != null && message.status == "error") {
                            this.global.utilities.notify.warning(message.internal_code == '' ? "Error, while saving the Data." : message.internal_code, "Warning")
                        } else {

                        }
                    }
                }
                else {
                    this.global.utilities.notify.error("Ërror occur while shipping RMA. Please contact with admin.")
                }
            },
            error => {
                this.global.utilities.notify.error("Ërror occur while receiving RMA. Please contact with admin.")
            }
        );
    }
    show_hide_common_receving_box(module, internal_code): boolean {
        if (module === "M013" || module === "M124" || module === "M091"
            || module === "M081" || module === "M134" || module === "M136"
            || module === "M152" || module === "M109") {
            if (internal_code !== undefined && internal_code !== null
                && (module === "M081" || module === "M013" || module === "M124"
                    || module === "M134" || module === "M136" || module === "M152" || module === "M109")) {
                return false;
            }
            else {
                return true;
            }
        }
        else { return false; }

    }
    check_duplicate_in_array(propertyName, inputArray) {
        var seenDuplicate = false,
            testObject = {};

        inputArray.map(function (item) {
            var itemPropertyName = item[propertyName];
            if (itemPropertyName in testObject) {
                testObject[itemPropertyName].duplicate = true;
                item.duplicate = true;
                seenDuplicate = true;
            }
            else {
                testObject[itemPropertyName] = item;
                delete item.duplicate;
            }
        });

        return seenDuplicate;
    }

    check_array_has_same_value(propertyName, inputArray) {
        var seenDuplicate = false;
        for (let i = 0; i < inputArray.length; i++) {
            if (inputArray[i].field == propertyName) {
                seenDuplicate = true;
            }
        }
        return seenDuplicate;
    }
    remove_object_from_array_using_key(incoming_array, key) {
        var removeIndex = incoming_array.map(function (item) { return item.field; }).indexOf(key);

        // remove object
        incoming_array = incoming_array.splice(removeIndex, 1);
        return incoming_array;
    }

    getFileExtension(filename) {
        return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
    }

    check_File_Size(file) {
        return (file.size / 1024 / 1024).toFixed(2);
    }

    setMaskPhone(type: number): any {
        if (type < 0 || type > 1) {
            return;
        }
        if (type === 0) {
            return '(000) 000-0000';
        } else {
            return '000000000000000000000000000000';
        }
    }

    setMaskZip(type: number): any {

        if (type < 0 || type > 1) {
            return;
        }
        if (type === 0) {
            return '00000-0000';
        } else {
            return 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA';
        }
    }

    GetProjectUserWise(userid: number) {
        let promise = new Promise((resolve, reject) => {
            this.helper.spName = "usp_get_project_role_users";
            let param: any = {};
            param.userid = userid;
            this.helper.payload = JSON.stringify(param);
            this.global.ServiceManager.request.post('Grid/ExecuteProcedure', this.helper).subscribe(
                (res: any) => {
                    if (res.status == 1) {
                        if (res.data.outList[0].parameterValue != '') {
                            var lists = JSON.parse(res.data.outList[0].parameterValue);
                            resolve(lists);
                        }
                        else
                            resolve(null);
                    }
                    else
                        reject(null);
                },
                error => {
                    console.log(error);
                }
            );
        });
        return promise;
    }

    /**
     * assign the ticket to assignent to user
     */
    assign_at_awatingpick(order_batch_number, user_id) {
        var param: any = {};
        param.order_batch_number = order_batch_number;
        param.user_id = user_id;
        param.internal_status_code = 'STA-063';
        this.helper.spName = "usp_inv_IOAssignment";
        this.helper.payload = JSON.stringify(param);
        this.global.ServiceManager.request.post('Grid/ExecuteProcedure', this.helper).subscribe(
            (res: any) => {
                if (res.status == 1) {
                    let batchflag = JSON.parse(res.data.outList[0].parameterValue).batchflag;
                    let internal_code = JSON.parse(res.data.outList[0].parameterValue).internal_code;
                    if (JSON.parse(res.data.outList[0].parameterValue).code == 'already') {
                        if (batchflag) {
                            this.global.utilities.notify.warning("Batch# is already assigned.");
                        }
                        else {
                            this.global.utilities.notify.warning("Order# is already assigned.");
                        }
                    }
                    else if (JSON.parse(res.data.outList[0].parameterValue).code == 'success') {

                        if (batchflag) {
                            this.global.utilities.notify.success("Orders for batch# " + order_batch_number + " has been assigned successfully.");
                            this.router.navigate(['en-us/support/inventory/workbench/pick']);
                        }
                        else {
                            this.router.navigate(['en-us/support/inventory/workbench/pick/' + internal_code]);
                            this.global.utilities.notify.success("Order# " + order_batch_number + " has been assigned successfully.");
                        }
                    }
                }
                else {
                    this.global.utilities.notify.error("Error occur while IO assignment.")
                }
            },
            error => {
                console.log(error);
            }
        )
    }
    isEmail(search: string): boolean {
        var serchfind: boolean;
        let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        serchfind = regexp.test(search);
        return serchfind
    }
}
