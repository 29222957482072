import { Component, OnInit } from '@angular/core';
import { helper } from '../Shared/helper';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/autnentication-service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../admin/GlobalService/global-service';
import { AppLoaderServiceMessaging } from '../Shared/app-loader-service-messaging';

@Component({
  selector: 'app-getpassword',
  templateUrl: './getpassword.component.html',
  styleUrls: ['./getpassword.component.css']
})
export class GetpasswordComponent implements OnInit {
  _env: any;
  IsLoading: boolean;
  user = { username: "", password: "", org_id: 0 }
  userroleList: any = [];
  helper = new helper();
  internal_code: any;
  role_id: any = "0";
  _environment: any;
  org_code: any = "BEML";
  org_details: any;
  org_List: any;
  private http: HttpClient

  constructor(private auth: AuthenticationService, private global: GlobalService,
    private activated:ActivatedRoute,
    private _router: Router,
    private _loader_service: AppLoaderServiceMessaging) {


  }
  async ngOnInit() {
    this.org_code=this.activated.snapshot.paramMap.get('org_code');
    
    setTimeout(() => {
      // this.login();
    }, 500);
    //   this.login();
  }

  async login() {
    // debugger;
    // this._loader_service.show_loader();

    $(".preloader").fadeOut();
    let valid = true;

   
    if(this.user.username.trim()=='')
    {
      this.global.utilities.notify.error('Please enter Email ID', 'Error');
      return;
    }
    if (this.validateEmail(this.user.username) == false) {
      this.global.utilities.notify.error('Please Enter valid Email ID', '');
      return;
    }
    if (valid === true) {
      const user: any = {
        Email: this.user.username,
       
        Advertisement:1
      }
      this.IsLoading = true;
      var param: any = {};
  
      this.helper.spName = "usp_forgotpassword";
      this.helper.payload = JSON.stringify(user);
      this.global.ServiceManager.request.post('Global/GetDataFromServer', this.helper).subscribe(res => {
        this.global.utilities.HideLoder();

        if (res.status == 1) {
    
          if (res.data.dataset.table.length > 0 && res.data.dataset.table[0].response == 'success') {
            
            
              if (res.data.dataset.table.length > 0) {
                this.sentEmail(this.user.username, res.data.dataset.table[0].enrollment_no, res.data.dataset.table[0].password, res.data.dataset.table[0].first_name);
              }
           // this.global.utilities.storage.set('Enrollment_No', this.user.username);
          //  this.global.utilities.storage.set('Password', this.user.password);
          // this._router.navigate(['/login']);
          }
          else {
            this.IsLoading = false;
            // this.global.utilities.notify.error('Invalid Enrollment No Or DOB.');
            this.global.utilities.notify.ShowAlert('Record Not Found', '');
            return;
          }
  
        }
  
  
      }
        , err => {
          
          this.global.utilities.notify.error('Something went wrong. Try again.', '');
          this.IsLoading = false;
        }
      )
  
  
      console.log(user)
    }
  }

  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return (true)
    }
    return (false)
  }
  BindOrgDetails()
  {
    this.org_details =this.org_List.filter(x=>x.org_id== this.user.org_id)[0];
  }
  sentEmail(email, refNo, pwd, name) {
    var param: any = {};
    param.To = email;
   
    param.Subject = "Forgot Password.";
    param.Password = "";
    param.Body = btoa(`Dear ${name} ,<br/>
  <br/>
  

  <br/>
  Your Registration No is: <b>${refNo}</b><br/>
  Your Password is: <b>${pwd}</b><br/>
  <br/>
  <br/>
  <br/>
  Regards,
  <br/>
  <b>BEML</b>
  <br/>
  <p>Note: This is a system generated email, please do not reply to this message.</p>
  `);
    this.global.ServiceManager.request.postWithoutEncryptionEmail('WeatherForecast/sendemail', param).subscribe(x => {
      this.global.utilities.notify.ShowAlert('We have sent the user id and password to your email.', '');
      this._router.navigate(['./login'])
      this.IsLoading = false;
    }
      , err => {
        this.global.utilities.notify.error('Something went wrong. Try again.', '');
        this.IsLoading = false;
      }
    )
  }
}
