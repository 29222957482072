import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contactsupport',
  templateUrl: './contactsupport.component.html',
  styleUrls: ['./contactsupport.component.css']
})
export class ContactsupportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
