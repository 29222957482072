import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../admin/GlobalService/global-service';

@Component({
  selector: 'app-firsthome',
  templateUrl: './firsthome.component.html',
  styleUrls: ['./firsthome.component.css']
})
export class FirsthomeComponent implements OnInit {

  constructor(private global: GlobalService) { }

  ngOnInit(): void {
    sessionStorage.setItem('IsAccess','0');
    //this.global.utilities.notify.ShowAlert('Last date of form submission is 18th Oct 2023 1800 hours','');
  }


  //18th Oct 2023


}
