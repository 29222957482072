export const environment = {
  name: 'Dev',
  production: true,
  bannerclass: 'topbanner_dev1',
  appinsightkey: '',
  base: 'https://cliflyerapitest.azurewebsites.net/api',
  base__: 'http://localhost:5000/api',
  clientbase: 'https://api.exmegov.com/api/',
  SOCKET_ENDPOINT:'http://kitapi.kkminfotech.com/',
  lang: 'en-us',
  salt: '@exam@',
  con:'_KPS17_2024',
  authentication: 'security',
  support: 'support',
  front: 'portal',
  cacheLocation: localStorage,
  zeroid: '0',
  rl_protocol: 'IN_APP',//IN_APP_AD, AZ_AD
  IN_APP: {
    applicationId: '', // Application Id of app registered under app.
    clientSecret: '', // Secret generated for app. Read this environment variable.
  }
};