import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  public apiUrl = '';
  public org_id=0;
  public logo_path='';
  public company_name='';
  public name: 'Dev';
  public production: true;
  public bannerclass: 'topbanner_dev1';
  public appinsightkey: '';
  public  base: 'http://examapi.kkminfotech.com/api';
  public base_Live: 'http://localhost:5000/api';
  public clientbase: 'http://examapi.kkminfotech.com/';
  public SOCKET_ENDPOINT:'http://kitapi.kkminfotech.com/';
  public lang: 'en-us';
  public salt: '@exam@';
  public authentication: 'security';
  public support: 'support';
  public front: 'portal';
  public zeroid: '0';
  public rl_protocol: 'IN_APP';//IN_APP_AD, AZ_AD
  public IN_APP: {
     applicationId: '', // Application Id of app registered under app.
    clientSecret: '', // Secret generated for app. Read this environment variable.
  }



  // Whether or not to enable debug mode
  public enableDebug = true;
  constructor() { }
}
